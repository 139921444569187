.list {
  list-style: none;
}

#root
  > div
  > div.center
  > div
  > ul
  > li:nth-child(1)
  > div.list_item__description {
  //body > div.center > div > ul > li:nth-child(1) > div.list_item__description {
  border-left: 2px solid rgb(227, 229, 232);
}

.list_item {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  height: 250px;
  overflow: hidden;

  /*
    &:nth-of-type(even) {
        background-color: #f00;
    }


    &:nth-of-type(odd) {
        background-color: #00f;
    }
    */

  &:first-child {
    max-height: 100px;
  }

  &:nth-child(odd) > div.list_item__description {
    order: 1;
    align-items: flex-start;
  }

  &__description {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-weight: 600;
    font-size: 0.8rem;
    text-transform: uppercase;
    //font-style: italic;
    &:nth-child(1) {
      align-items: flex-end;
    }

    &:nth-child(2) {
      align-items: flex-end;
    }

    span {
      &:first-child {
        color: rgb(197, 198, 201);
        font-size: 3rem;
        font-weight: 600;
        font-style: normal;
      }
    }
  }

  &__image {
    overflow: hidden;
    &:nth-child(even) {
      align-items: flex-end;
    }

    img {
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
  }
}
