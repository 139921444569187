html,
body,
* {
  padding: 0;
  margin: 0;
  //background: rgb(239, 241, 245);
  font-family: "Open Sans", sans-serif;
}
html {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(239, 241, 245);
}
.left {
  background: rgb(239, 241, 245);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 45%;
  max-width: calc(50% - 100px);
  overflow: hidden;
  padding: 2em;
  background-image: url("../public/logo_white.png");
  background-size: 140% auto;
  background-position: 140%;
  //background-size: 300px 300px;
  background-repeat: no-repeat;

  .big_logo {
    display: flex;

    color: rgb(150, 150, 150);
    flex-direction: column;
    //position: absolute;
    bottom: 0;
    font-size: 4rem;
    font-family: "Open Sans", sans-serif;
    //letter-spacing: 5px;
    font-weight: 900;
    //width: 100%;
    //text-transform: uppercase;
    //width: 100%;
    text-align: right;
    //left: 50%;
    //width: 90%;

    span {
      background-color: red;
      flex-grow: 1;
    }
  }
}
.center {
  //background: #bbb;
  position: absolute;
  //left: 30%;
  right: 50px;
  max-width: 50%;
  min-width: 50%;
  top: 0;
  bottom: 0;

  overflow-y: auto;
  border-left: 2px solid rgb(227, 229, 232);
}

.right {
  display: flex;

  justify-content: center;
  margin: auto;
  border-left: 2px solid rgb(227, 229, 232);
  background: rgb(239, 241, 245);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  overflow: hidden;
  padding-top: 20px;

  .logo_text {
    text-transform: uppercase;
    letter-spacing: 10px;
    transform: rotate(90deg);
    position: absolute;
    //right: -150px;
    top: 193px;
    font-family: "Open Sans", sans-serif;
    font-weight: 900;
    color: #aaa;
  }
}

.logo {
  color: rgb(150, 150, 150);
  position: absolute;
  bottom: 0;
  //text-align: right;
  //width: 100%;
  right: 140px;
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  letter-spacing: 5px;

  span {
    -webkit-text-stroke: 1px rgb(150, 150, 150);
    color: rgba(0, 0, 0, 0);
  }
}
