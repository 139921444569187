.contact {
  display: flex;
  flex-direction: column;
  height: 100%;
  //background-color: red;
  justify-content: center;
  align-items: center;

  .name {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .email {
  }

  .ytunnus {
    margin-top: 20px;
    font-style: italic;
  }

  //  div:last-of-type {
  //    margin-top: auto;
  //  }
}
